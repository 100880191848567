<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import { postAction, putAction } from '@/command/netTool'

export default {
  name: 'iotDetail',
  data() {
    return {
      categoryArr: [
        {
          name: '正常',
          value: '1',
        },
        {
          name: '超标',
          value: '2',
        },
      ],
      scjyArr: [
        {
          name: '正常',
          value: '1',
        },
        {
          name: '断开',
          value: '2',
        },
      ],
      deviceArr: [
        {
          name: 'A级',
          value: '1',
        },
        {
          name: 'B级',
          value: '2',
        },
        {
          name: 'C级',
          value: '3',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '物联网设备名称',
            type: 'input',
            key: 'mainName',
            cols: 12,
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '设备归属',
            type: 'select',
            cols: 12,
            key: 'creditCode',
            typeData: this.categoryArr,

            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '型号规格',
            type: 'input',
            cols: 12,
            key: 'personName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '设备等级',
            type: 'select',
            cols: 12,
            key: 'idCard',
            typeData: this.deviceArr,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '收发频率',
            type: 'input',
            cols: 12,
            key: 'linkPhone',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '限定状态',
            type: 'select',
            cols: 12,
            key: 'mainBodyType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: this.categoryArr,
          },
          {
            name: '创建时间',
            type: 'datePicker',
            cols: 12,
            key: 'produce',
            props: {
              showTime: true,
              format: 'YYYY-MM-DD',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
              placeholder: '请选择创建日期',
            },
          },
          {
            name: '连接状态',
            type: 'select',
            cols: 12,
            key: 'producerType',
            typeData: this.scjyArr,
          },
          //    {
          //     name: '连接状态',
          //     type: 'switch',
          //     cols: 12,
          //     key: 'producerType',
          //     typeData: this.scjyArr,
          //   },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: (data) => {
            if (data.id) {
              putAction('/api/', {
                ...data,
              }).then((res) => {
                this.$router.push('/iot')
              })
            } else {
              console.log(data)
              postAction('/api/', {
                ...data,
              }).then((res) => {
                this.$router.push('/iot')
              })
            }
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/iot'),
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} data={[this.getForm1()]} />
  },
}
</script>
